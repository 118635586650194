import React, { useState, useLayoutEffect, useEffect } from 'react';
import clsx from 'clsx';
import { IconButton } from '@Components/';
import { NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon } from '@SvgIcon/';

const ScrollerArrowButton = (props) => {
    const { parentRef, arrowLeftPos = 0, arrowRightPos = 0, moveDistance = 300 } = props;
    const [showRight, setShowRight] = useState(false);
    const [showLeft, setShowLeft] = useState(false);
    const [isMounted, setMounted] = useState(false);

    const leftPos = arrowLeftPos === 0 ? {} : { left: arrowLeftPos * 1 };
    const rightPos = arrowRightPos === 0 ? {} : { right: arrowRightPos * 1 };

    const handleScrollLeftOnClik = () => {
        if (!parentRef.current) return;
        parentRef.current.scrollLeft -= moveDistance;
    };

    const handleScrollRightOnClik = () => {
        if (!parentRef.current) return;
        parentRef.current.scrollLeft += moveDistance;
    };

    const handleOnScroll = () => {
        if (!parentRef.current && !isMounted) return;
        const { scrollLeft, clientWidth, scrollWidth: max } = parentRef.current;
        const pos = scrollLeft + clientWidth;
        if (scrollLeft === 0) setShowLeft(false);
        else if (pos === max) setShowRight(false);
        else {
            setShowLeft(true);
            setShowRight(true);
        }
    };

    // 監聽寬度是否有變化
    const handleResizeObserver = () => {
        new ResizeObserver(() => {
            if (!parentRef.current && !isMounted) return;
            const { scrollWidth, clientWidth } = parentRef.current;

            if (scrollWidth === clientWidth) setShowRight(false);
            else setShowRight(true);
        }).observe(parentRef.current);
    };

    useLayoutEffect(
        () => {
            if (!parentRef.current) return;
            parentRef.current.classList.add('scroll-arrow-wrap');
            parentRef.current.addEventListener('scroll', handleOnScroll);
            handleResizeObserver();
        },
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        setMounted(true);
        return () => {
            setMounted(false);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div style={{ ...leftPos }} className={clsx('scroller-left-button', !showLeft && 'hidden')}>
                <IconButton className="arrow-button" onClick={handleScrollLeftOnClik}>
                    <NavigateBeforeIcon />
                </IconButton>
            </div>
            <div style={{ ...rightPos }} className={clsx('scroller-right-button', !showRight && 'hidden')}>
                <IconButton className="arrow-button" onClick={handleScrollRightOnClik}>
                    <NavigateNextIcon />
                </IconButton>
            </div>
        </React.Fragment>
    );
};

export default ScrollerArrowButton;
