/**
 * @author Curves IT Team
 * isValidDate
 * isInstanceDate
 * initialDate
 * parseDate
 * getDateAddTime
 * getCurrentTimeSecond
 * getToday
 * getCurrentStartDate
 * getCurrentEndDay
 * getAddDays
 * getSubDays
 * isAfter
 * isBefore
 * isSame
 * getDiffDate
 * getFirstOfMonth
 * getLastOfMonth
 * getNowTime
 * getDateIterate
 * displayDateText
 * getAgeData
 * isSevenMeasured
 * isMonthDaysAfterError
 */

import moment from 'moment';
import { DateTimeStandard } from '../Components/pickers/enums/DateTimeStandard';

const replacWeekday = (num) => {
    switch (num) {
        case '(1)':
            return '(一)';
        case '(2)':
            return '(二)';
        case '(3)':
            return '(三)';
        case '(4)':
            return '(四)';
        case '(5)':
            return '(五)';
        case '(6)':
            return '(六)';
        case '(7)':
            return '(日)';
        default:
            return num;
    }
};

const isEmpty = (date) => date === null || date === undefined || date === '';

/**
 * isValidDate() 判斷是否為有效日期
 */
export const isValidDate = (_date) => !!(_date && moment(new Date(_date)).isValid());

/**
 * isValidDate() 判斷是否為日期實體物件 (出來的值如果是invalid Date 是日期物件格式)
 */
export const isInstanceDate = (_date) => _date instanceof Date;

/**
 * initialDate() 初始化所有api給的日期字串
 * @return {Date|String} element
 */
export const initialDate = (value) => (isEmpty(value) ? '' : new Date(value));

/**
 * parseDate() 轉換日期格式
 * @param {moment.Moment|Date|String} _date
 * @param {String} format   is date format which YYYY MM DD HH MM SS etc...
 * @return {String} dateTime
 */
export const parseDate = (_date = new Date(), format = 'YYYY-MM-DD') => {
    let dateTime;
    if (!isValidDate(_date)) return '';
    dateTime = moment(new Date(_date)).format(format);
    if (format.includes('(E)')) {
        dateTime = dateTime.replaceAll(/\(\d\)/g, (match) => replacWeekday(match));
    }
    return dateTime;
};

/**
 * getDateAddTime() 年月日加上秒數
 * @param {Date|String} _date
 * @param {Number} _time   ex: 1s ~ 86400s;
 * @param {Boolean} isInitHMS  秒時分是否 初始歸零
 * @return {String} element ex: 2022-05-01T12:00:00
 */
export const getDateAddTime = (_date, _time = 0, isInitHMS = true) => {
    if (!isValidDate(_date)) return '';
    let _day = _date;
    if (isInitHMS) _day = parseDate(_date);
    return moment(_day).add(_time, 'second').format('YYYY-MM-DDTHH:mm:ss');
};

/**
 * getCurrentTimeSecond() 計算出當天的秒數
 * @param {Date|String} _date
 * @return {Number} element ex: 1s ~ 86400s;
 */
export const getCurrentTimeSecond = (_date) => {
    if (!isValidDate(_date)) return null;
    const _Hms = moment(_date).format('HH:mm:ss').split(':');
    const _HH = parseInt(_Hms[0], 10) * 60 * 60;
    const _mm = parseInt(_Hms[1], 10) * 60;
    const _ss = parseInt(_Hms[2], 10) * 1;
    return _HH + _mm + _ss;
};

/**
 * getToday() 取得當下日期
 * @return {String} element ex:2022-05-01T00:00:00
 */
export const getToday = () => moment().format('YYYY-MM-DDT00:00:00');

/**
 * getCurrentStartDate() 取得起始日期
 * @param {Date} _date
 * @return {String} element ex:2022-05-01T00:00:00
 */
export const getCurrentStartDate = (_date = new Date()) => {
    if (!isValidDate(_date)) return '';
    return moment(_date).format('YYYY-MM-DDT00:00:00');
};

/**
 * getCurrentEndDay() 取得迄日日期
 * @param {Date} _date
 * @return {String} element ex:2022-05-01T23:59:59
 */
export const getCurrentEndDay = (_date = new Date()) => {
    if (!isValidDate(_date)) return '';
    return moment(_date).format('YYYY-MM-DDT23:59:59');
};

/**
 * getAddDays() 取得當天時間加該天數運算後的日期
 * @param {Date|String} _date
 * @param {Number} _num    // 要加幾天
 * @return {String} element ex:2022-05-01
 */
export const getAddDays = (_date = new Date(), _num) => {
    if (!isValidDate(_date)) return '';
    const num = parseInt(String(_num), 10) || 0;
    return moment(_date).add(num, 'days').format('YYYY-MM-DD');
};

/**
 * getAddMonths() 取得當天時間加該月數運算後的日期
 * @param {Date|String} _date
 * @param {Number|String} _num    // 要加幾個月
 * @return {String} element ex:2022-05-01
 */
export const getAddMonths = (_date = new Date(), _num) => {
    if (!isValidDate(_date)) return '';
    const num = parseInt(String(_num), 10) || 0;
    return moment(_date).add(num, 'months').format('YYYY-MM-DD');
};

/**
 * getSubDays() 取得當天時間剪該天數運算後的日期
 * @param {Date} _date
 * @param {Number|String} _num
 * @return {String} element ex:2022-05-01
 */
export const getSubDays = (_date = new Date(), _num) => {
    if (!isValidDate(_date)) return '';
    const num = parseInt(String(_num), 10) || 0;
    return moment(_date).subtract(num, 'days').format('YYYY-MM-DD');
};

/**
 * getSubMonths() 取得當天時間減該月數運算後的日期
 * @param {Date|String} _date
 * @param {Number|String} _num
 * @return {String} element ex:2022-05-01
 */
export const getSubMonths = (_date = new Date(), _num) => {
    if (!isValidDate(_date)) return '';
    const num = parseInt(String(_num), 10) || 0;
    return moment(_date).subtract(num, 'months').format('YYYY-MM-DD');
};

/**
 * isAfter() 結束時間是否在開始時間之後
 * @param {Date|String} start_date 起始時間
 * @param {Date|String} end_date   結束時間
 * @return {Boolean|String}
 */
export const isAfter = (start_date, end_date, _format = 'YYYY-MM-DD') => {
    try {
        _format = _format.replaceAll('/', '-'); // 消除警告
        if (!isValidDate(start_date)) throw new Error('Start Date Invalid date');
        else if (!isValidDate(end_date)) throw new Error('End Date Invalid date');
    } catch (e) {
        console.log(e.name + ': ' + e.message);
        return '';
    }
    const sDate = parseDate(start_date, _format);
    const eDate = parseDate(end_date, _format);
    return moment(sDate).isAfter(moment(eDate));
};

/**
 * isBefore() 結束時間是否在開始時間之前
 * @param {Date|String} start_date 起始時間
 * @param {Date|String} end_date   結束時間
 * @return {Boolean|String}
 */
export const isBefore = (start_date, end_date, _format = 'YYYY-MM-DD') => {
    try {
        if (!isValidDate(start_date)) throw new Error('Start Date Invalid date');
        else if (!isValidDate(end_date)) throw new Error('End Date Invalid date');
    } catch (e) {
        console.log(e.name + ': ' + e.message);
        return '';
    }
    const sDate = parseDate(start_date, _format);
    const eDate = parseDate(end_date, _format);
    return moment(sDate).isBefore(moment(eDate));
};

/**
 * isSame() 起始時間與結束時間是否相同
 * @param {Date|String} start_date 起始時間
 * @param {Date|String} end_date   結束時間
 * @return {Boolean|String}
 */
export const isSame = (start_date, end_date, _format = 'YYYY-MM-DD') => {
    try {
        if (!isValidDate(start_date)) throw new Error('Start Date Invalid date');
        else if (!isValidDate(end_date)) throw new Error('End Date Invalid date');
    } catch (e) {
        console.log(e.name + ': ' + e.message);
        return '';
    }
    const sDate = parseDate(start_date, _format);
    const eDate = parseDate(end_date, _format);
    return moment(sDate).isSame(moment(eDate));
};

/**
 * getDiffDate() 判斷日期間的時間差異
 * @param {Date|string} start_date                                          起始時間
 * @param {Date|string} end_date                                            結束時間
 * @param {moment.unitOfTime.Base} diffString (years|months|weeks|days|hours|minutes|seconds)      預設比較天數
 * @param {Boolean} isAbs                                            回傳是否加絕對值
 * @return {Number}
 */
export const getDiffDate = (start_date, end_date, diffString = 'days', isAbs = false) => {
    try {
        if (!isValidDate(start_date)) throw new Error('Start Date Invalid date');
        else if (!isValidDate(end_date)) throw new Error('End Date Invalid date');
        else if (['year', 'month', 'weeks', 'days', 'hours', 'minutes', 'seconds'].indexOf(diffString) === -1) {
            throw new Error('Diff String must be (years|months|weeks|days|hours|minutes|seconds)');
        }
    } catch (e) {
        console.log(e.name + ': ' + e.message);
        return NaN;
    }
    const sDate = moment(start_date);
    const eDate = moment(end_date);
    const result = sDate.diff(eDate, diffString);
    return isAbs ? Math.abs(result) : result;
};

/**
 * getFirstOfMonth() 取得該日期的月首日期
 * @param {Date|String} _date  初始設定日期
 * @param {Number} num   與初始日相差多少月
 * @return {String|Date}
 */
export const getFirstOfMonth = (_date, num = 0) => {
    if (!isValidDate(_date)) {
        console.warn('Your _date:' + _date + 'is not valid Date');
        return _date;
    }
    return moment(_date).add(num, 'month').startOf('month').format('YYYY-MM-DD');
};

/**
 * getLastOfMonth() 取得該日期的月末日期
 * @param {Date|String} _date  初始設定日期
 * @param {Number} num   與初始日相差多少月
 * @return {String|Date}
 */
export const getLastOfMonth = (_date, num = 0) => {
    if (!isValidDate(_date)) {
        console.warn('Your _date:' + _date + 'is not valid Date');
        return _date;
    }
    return moment(_date).add(num, 'month').endOf('month').format('YYYY-MM-DD');
};

export const getNowTime = () => {
    return moment().format('YYYY-MM-DDTHH:mm:ss');
};

/**
 * 產生迭代日期
 * @param {String|Date} startDate
 * @param {String|Date} endDate
 * @returns {Array}
 */
export const getDateIterate = (startDate, endDate) => {
    let sD = moment(startDate);
    let eD = moment(endDate);
    if (sD > eD) {
        sD = moment(endDate);
        eD = moment(startDate);
    }
    let datesAry = [];
    let curDate = sD;
    do {
        const addOneDate = parseDate(curDate, 'YYYY-MM-DD');
        datesAry.push(addOneDate);
        curDate = moment(curDate).add(1, 'day');
    } while (curDate <= eD);
    return datesAry;
};

/**
 * 顯示 日期內容
 * @param {String|Date} startDate
 * @param {String|Date} endDate
 * @param {Object} _
 * @returns {String}
 */
export const displayDateText = (startDate = '', endDate = '', { defaultText = '無相關資料', format = DateTimeStandard.DisplayDateBySlash } = {}) => {
    // if (!startDate || !endDate) return defaultText;
    let sD = parseDate(startDate, format);
    let eD = parseDate(endDate, format);
    let text = '';
    if (startDate) text = text + sD;
    if (startDate && endDate) text = text + ' ~ ';
    if (endDate) text = text + eD;
    if (!sD && !endDate) text = defaultText;
    return text;
};

/**
 * 顯示年齡
 * @param {String|Date} dateString
 * @returns {{years: Number, month: Number, days: Number}| {}}
 */
export const getAgeData = (dateString) => {
    if (!isValidDate(dateString)) return {};
    const date = moment(dateString, 'YYYY-MM-DD');
    const years = moment().diff(date, 'years');
    const month = moment().diff(date.add(years, 'years'), 'month', false);
    const days = moment().diff(date, 'days', false);
    return { years, month, days };
};

/**
 * 比較月份日期 誰比較大 都使用2000(leap year)作為基底比較
 * @param {String|Date} startDate
 * @param {String|Date} endDate
 * @returns {Boolean}
 */
export const isMonthDaysAfterError = (startDate, endDate) => {
    if (!isValidDate(startDate) || !isValidDate(endDate)) return false;
    const sD = moment(startDate).year(2000);
    const eD = moment(endDate).year(2000);
    return sD.isAfter(eD);
};

/**
 * 比較是否有時間格式
 * @param {String} datetimeString
 * @returns {Boolean}
 */
export const hasTimeCheck = (datetimeString) => {
    let isTime = false;
    if (typeof datetimeString !== 'string') return isTime;
    const datetimeArray = datetimeString.split('T');
    const time = datetimeArray[1] || '';
    if (/^\d{2}:\d{2}:\d{2}.*/g.test(time)) isTime = true;
    return isTime;
};

/**
 * 判斷日期格式
 * @param {String} datetime
 * @returns {String}
 */
export const getDatetime = (datetime) => {
    if (isValidDate(datetime)) {
        return parseDate(datetime);
    } else {
        return parseDate();
    }
};
